import { useAuthStore } from '@/stores/auth';
const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxODE0ODI0MzA1LCJpYXQiOjE3Mjg0MjQzMDUsImp0aSI6IjdiNzg4ZTU2MmJlMzRkYTM5MTY2OGY4YjAyZWIxN2Q2IiwiY2xpZW50X2hhc2giOiI3ZWQ2MGJjYjMyYjNmYzZiZTVkOGE3NjcyNjZiY2Q4OWNjODM2YTZkIn0.3njICopTOPJzaM_Dti193zUJh4-RPXVVBEAw_IvM73E"

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

function request(method) {
    return (url, body) => {
        const requestOptions = {
            method,
            headers: authHeader(url)
        };
        requestOptions.headers['Authorization'] = "Bearer " + token;
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(url, requestOptions).then(handleResponse);
    }
}

function authHeader(url) {

    const baseUrl = 'https://ya.ru' // import.meta.env.VITE_API_URL
    const { user } = useAuthStore();
    const isLoggedIn = !!user?.token;
    const isApiUrl = url.startsWith(baseUrl);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${token}` }
        // return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            const { user, logout } = useAuthStore();
            if ([401, 403].includes(response.status) && user) {
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}