<template>
  <div class="anketa-fillOut">
    <span class="ostint-x-capsula"></span>
    <span>
      <img src="@/assets/img/anketa/fillout-animation.png" alt="">
    </span>
    <span class="description">{{ promoText }}</span>
    <primary-button
        :text="button.text"
        :link="button.link" />
  </div>
</template>

<script>
import { useHeaderStore } from '@/stores/header'
import headerMixin from "@/mixins/headerMixin";

export default {
  name: 'FillOutView',
  mixins: [headerMixin],
  props: ['header'],
  setup() {
    const {setHeaderData} = useHeaderStore();
    return {
      setHeaderData
    };
  },
  data(){
    return {
      promoText: 'Расскажи о себе, и получи персональную карту стиля и 5 образов от стилистов',
      button:{
        text: 'Заполнить анкету',
        link: 'anketa.step1',
      }
    }
  }
}
</script>
