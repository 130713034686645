import {createRouter, createWebHashHistory} from 'vue-router'
import EyeQuestionView from "@/views/Anketa/EyeQuestionView";
import FillOutView from "@/views/Anketa/FillOutView";
import SkinQuestionView from "@/views/Anketa/SkinQuestionView";
import FigureQuestionView from "@/views/Anketa/FigureQuestionView";
import FaceQuestionView from "@/views/Anketa/FaceQuestionView";
import StyleQuestionView from "@/views/Anketa/StyleQuestionView";
import HeightQuestionView from "@/views/Anketa/HeightQuestionView";
import TopQuestionView from "@/views/Anketa/TopQuestionView";
import BottomQuestionView from "@/views/Anketa/BottomQuestionView";
import CompletionQuestionView from "@/views/Anketa/CompletionQuestionView";
import {useAuthStore} from "@/stores/auth";
import {useSettingsStore} from "@/stores/settings";
import {useAnketaStore} from "@/stores/anketa";

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
        props: {
            header: {
                back: false,
                title: 'Мои образы'
            },
          bar: {
                text: "НОВЫЕ ОБРАЗЫ ПОЯВЯТСЯ УЖЕ В НОЯБРЕ!",
                disabled: false
            }
        }
    },
    {
        path: '/personal',
        name: 'personal',
        component: () => import(/* webpackChunkName: "personal" */ '../views/PersonalCardView.vue'),
        props: {
            header: {
                back: true,
                title: 'Личная карта стиля'
            },
        }
    },
    {
        path: '/styles',
        name: 'styles',
        component: () => import(/* webpackChunkName: "styles" */ '../views/StylesView.vue'),
        props: {
            header: {
                back: true,
                title: 'Образы от стилистов'
            },
        }
    },
    {
        path: '/other',
        name: 'other',
        component: () => import(/* webpackChunkName: "other" */ '../views/AllStylesView.vue'),
        props: {
            header: {
                back: true,
                title: 'Все образы'
            },
        }
    },
    {
        path: '/compilation/:id?',
        name: 'compilation',
        component: () => import(/* webpackChunkName: "compilation" */ '../views/CompilationView.vue'),
        props: {
            header: {
                back: true,
                title: 'Подборка №'
            },
        }
    },
    {
        path: '/anketa',
        name: 'anketa',
        redirect: '/anketa/fillout',
        component: () => import(/* webpackChunkName: "anketa" */ '../views/Anketa/AnketaView.vue'),
        children: [
            {
                name: "anketa.fillout",
                path: '/anketa/fillout',
                component: () => import(/* webpackChunkName: "fillout" */ '../views/Anketa/FillOutView.vue'),
                props: {
                    header: {
                        back: false,
                        title: 'Мои образы'
                    }
                }
            },
            {
                name: "anketa.step1",
                path: '/anketa/step1',
                component: () => import(/* webpackChunkName: "step1" */ '../views/Anketa/HairQuestionView.vue'),
                props: {
                    header: {
                        back: true,
                        title: '1 из 9'
                    }
                }
            },
            {
                name: "anketa.step2",
                path: '/anketa/step2',
                component: EyeQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '2 из 9'
                    }
                }
            },
            {
                name: "anketa.step3",
                path: '/anketa/step3',
                component: SkinQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '3 из 9'
                    }
                }
            },
            {
                name: "anketa.step4",
                path: '/anketa/step4',
                component: FigureQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '4 из 9'
                    }
                }
            },
            {
                name: "anketa.step5",
                path: '/anketa/step5',
                component: FaceQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '5 из 9'
                    }
                }
            },
            {
                name: "anketa.step6",
                path: '/anketa/step6',
                component: StyleQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '6 из 9'
                    }
                }
            },
            {
                name: "anketa.step7",
                path: '/anketa/step7',
                component: HeightQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '7 из 9'
                    }
                }
            },
            {
                name: "anketa.step8",
                path: '/anketa/step8',
                component: TopQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '8 из 9'
                    }
                }
            },
            {
                name: "anketa.step9",
                path: '/anketa/step9',
                component: BottomQuestionView,
                props: {
                    header: {
                        back: true,
                        title: '9 из 9'
                    }
                }
            },
            {
                name: "anketa.completion",
                path: '/anketa/completion',
                component: CompletionQuestionView,
                props: {
                    header: {
                        back: true,
                        title: 'Мои образы'
                    }
                }
            },
        ],
    },
    {
        path: '/about',
        name: 'about',
    },
    {
        path: '/error',
        name: 'error',
        props: {
            header: {
                back: true,
                title: 'Мои образы'
            },
        },
        component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
        children: [
            {
                name: "error.505",
                path: '/error/505',
                component: () => import(/* webpackChunkName: "error" */ '../components/Error/Error505.vue'),
                props: {
                    header: {
                        back: false,
                        title: 'Мои образы'
                    }
                }
            },
            {
                name: "error.event",
                path: '/error/event',
                component: () => import(/* webpackChunkName: "error" */ '../components/Error/ErrorEvent.vue'),
                props: {
                    header: {
                        back: true,
                        title: 'Мои образы'
                    }
                }
            },
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach(async (to) => {
    const publicPages = [
        '/anketa',
        '/anketa/fillout',
        '/error/505'
    ];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    // if (!auth.user && to.query.client_id){
    //     try {
    //         auth.login(to.query.name, to.query.client_id)
    //             .catch(function (e){
    //                 console.log(e)
    //                 // error => setErrors({ apiError: error })
    //             });
    //     } catch (e){
    //         console.log(e)
    //         return '/anketa';
    //     }
    // }
    //
    // if (authRequired && !auth.user) {
    //     auth.returnUrl = to.fullPath;
    //     return '/anketa';
    // }
});

export default router
