export default {
    methods:{
        select(value,name){
            this.setValue(this.steps[this.step_number],value,name)
            this.active_item = value;
            this.selected = true;
            this.$emit('isSelected')
        },
    },
    watch: {
        active_item(){
            return this.getStepData(this.steps[this.step_number])
        }
    },
    computed: {
        active_item:{
            get() {
                return this.getStepData(this.steps[this.step_number])
            },
        }
    },
    data(){
        return {
            selected: false,
        }
    }
}