import { defineStore } from 'pinia'

export const useHeaderStore = defineStore('header', {
  state: () => (
      {
        header : {
          title: "",
          back: false,
          link: "/",
        }
      }
  ),
  getters: {
    // doubleCount: (state) => state.count * 2,
  },
  actions: {
    setHeaderData(back,title){
      this.header.back = back;
      this.header.title = title;
    },
    setTitle(text) {
      this.header.title = text;
    },
    showBackButton(bool) {
      this.header.back = bool;
    }
  },

})
