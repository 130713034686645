import { defineStore } from 'pinia'
import { fetchWrapper } from '@/helpers/auth';
import common from "@/composables/common";
import router from "@/router";
const baseUrl = `https://ostin.thecapsula.ru/api`;

export const useSettingsStore = defineStore('settings', {
  state: () => (
      {
        settings: JSON.parse(localStorage.getItem("settings")) || null,
        returnUrl: null
      }
  ),
  actions: {

    async fetchApiSettings(){
      if (this.settings === null) {
        try {
          const api = await fetchWrapper.get(`${baseUrl}/settings/`);
          common.saveToLocalStorage("settings",api.form)
          console.log(api)
        } catch (e) {
          return router.push({name: 'error.505'})
        }
      } else{
        return this.settings
      }
    },

    async postUserAnketa(anketa){
        try {
          const api = await fetchWrapper.post(`${baseUrl}/form/`, anketa);
          console.log(api)
        } catch (e) {
          return router.push({name: 'error.505'})
        }
    },
    async getUserAnketa(){
        try {
          const api = await fetchWrapper.get(`${baseUrl}/form/`);
          console.log(api)
        } catch (e) {
          return router.push({name: 'error.505'})
        }
    },

    getSetting(val){
      let result = false
      var i = this.settings.findIndex(function (e) {
        return e.name == val;
      });
      i != -1
          ? result = this.settings[i].data.choices
          : result;
      return result;
    }



  }

})
