<template>
  <div class="anketa-2cols styles">
    <div v-html="title" class="title"></div>
    <div class="top">
      <VueScrollPicker
          :options="data"
          v-model="modelValue"
          empty="Error"
          placeholder="-"
      />
    </div>
    <primary-button
        @click="saveLastStep(button.link)"
        :disabled="disabled"
        :text="button.text"
        :link="button.link"/>
  </div>

</template>

<script>
import StyleBlock from "@/components/Anketa/StyleBlock";
import {useHeaderStore} from '@/stores/header'
import anketaMixin from "@/mixins/anketaMixin";
import {useAnketaStore} from "@/stores/anketa";
import {ref} from "vue";
import topData from "@/data/anketa/topData";
import headerMixin from "@/mixins/headerMixin";
import selectedMixin from "@/mixins/selectedMixin";
import {useSettingsStore} from "@/stores/settings";

export default {
  name: 'TopQuestionView',
  components: {StyleBlock},
  mixins: [anketaMixin, headerMixin, selectedMixin],
  props: ['header'],
  setup() {
    const {setValue, getStepData, steps, saveLastStep} = useAnketaStore();
    let modelValue = ref(getStepData(steps.step8))
    const {setHeaderData} = useHeaderStore();
    const settings = useSettingsStore();
    return {
      setHeaderData, setValue, saveLastStep,
      getStepData, steps, modelValue, settings
    };
  },
  watch: {
    modelValue(newVal) {
      this.select(newVal)
      this.disabled = newVal == null;
    }
  },
  mounted() {
    if (this.modelValue == null) {
      this.disabled = true
    }
  },
  data() {
    return {
      stepId: 'top_size',
      title: "<span>Выбери какой у тебя размер верха:</span>",
      button: {
        text: 'Продолжить',
        link: 'anketa.step9',
      },
      data: topData,
      step_number: "step8",
    }
  }
}
</script>
<style scoped>


</style>
