import { defineStore } from 'pinia';

import { fetchWrapper } from '@/helpers/auth';
import router from "@/router";

// const baseUrl = `${import.meta.env.VITE_API_URL}/users`;
const baseUrl = `http://localhost:8080/users`;

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        returnUrl: null
    }),
    actions: {
        async login(username, password) {
            // const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });
            let user = {name:username,client_id: password}
            this.user = user;
            localStorage.setItem('user', JSON.stringify(user));

            // redirect to previous url or default to home page
            router.push(this.returnUrl || '/anketa/fillout');
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            router.push('/anketa/fillout');
        }
    }
});