import {defineStore} from 'pinia'
import common from '@/composables/common'

export const useAnketaStore = defineStore('anketa', {
    state: () => (
        {
            anketa: JSON.parse(localStorage.getItem("anketa")) || [],
            steps: {
                step1: "hair_color",
                step2: "eye_color",
                step3: "skin_tone",
                step4: "body_type",
                step5: "face_feature",
                step6: "style",
                step7: "height",
                step8: "top_size",
                step9: "bottom_size",
                last: null,
                finished: false,
                Notifications: false,
                countDown: 86359,
            },
            settings: [],
        }
    ),
    getters: {
        getSettings(state) {
            return state.getSettings
        },
    },
    actions: {
        save() {
            common.saveToLocalStorage("anketa", this.anketa)
        },
        getAnketa(){
            let apiData = {}
            for(const el of this.anketa) {
                apiData[el.step] = el.value
            }
            console.log(apiData)
        },

        getStepData(step) {
            let data = false
            try {
                if (this.anketa) {
                    var i = this.anketa.findIndex(function (e) {
                        return e.step == step;
                    });
                    i != -1 ? data = this.anketa[i].value : data;
                }
                return data;
            } catch (e) {
                return data;
            }
        },

        setValue(step, value, name) {
            common.addOrReplace(this.anketa, {step: step, value: value, name: name})
            this.save();
        },

        saveLastStep(step){
            common.addOrReplace(this.anketa, {step: 'last', value: step})
            this.save();
        }
    },

})
