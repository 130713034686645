<template>
  <Header ></Header>
  <router-view/>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import {useSettingsStore} from "@/stores/settings";
import {useAnketaStore} from "@/stores/anketa";
import router from "@/router";

export default {
  name: 'HomeView',
  components: {
    Header
  },
  setup(){
    const settings = useSettingsStore();
    settings.fetchApiSettings().catch(function (e){
      console.log(e)
    });

    const anketaStore = useAnketaStore();
    const AnketaIsFinished = anketaStore.getStepData('finished')
    console.log(anketaStore.getStepData('last'))
    console.log(AnketaIsFinished)
    if (AnketaIsFinished !== true){
      router.push({name: anketaStore.getStepData('last')})
    }

  },
  data() {
    return {
    }
  },
  mounted: function () {

  }
}
</script>

<style lang="less">
@import '@/assets/less/common.less';
</style>
