<template>
  <div class="header">
    <a @click="$router.back(-1)">
      <div class="back" v-if="header.back"></div>
    </a>
    <span class="title">{{ header.title }}<span v-if="route.params.id">{{ route.params.id }}</span></span>
    <div class="link">
      <router-link :to="header.link"/>
    </div>
  </div>
</template>

<script setup>

import { useHeaderStore } from '@/stores/header'
import {useRoute} from "vue-router";
const store = useHeaderStore()
const { header } = store;
const route = useRoute()
</script>

<style scoped>

</style>